import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './component/Header/Header';
import ProductDetail from './Pages/ProductDetail/ProductDetail';
import ProductPage from './Pages/ProductPage/ProductPage';
import ReviewDetail from './Pages/ReviewDetail/ReviewDetail';
import Gradients from './Pages/Gradients/Gradients';
import CreatorPage from './Pages/CreatorPage/CreatorPage';
import ReviewDetail2 from './Pages/ReviewDetail2/ReviewDetail2';
import ScrollToTop from './Pages/ScrollToTop/ScrollToTop';
import ProfilePage from './Pages/ProfilePage/ProfilePage';
import './App.css';
import SignUp from './Pages/SignIn/SignUp';
import SignIn from './Pages/SignIn/SignIn';
import MainPage from './Pages/MainPage/MainPage';
import HeaderTwo from './component/HeaderTwo/HeaderTwo';
import ScoreWorks from './Pages/ScoreWorks/ScoreWorks';
import ComparePage from './Pages/ComparePage/ComparePage';
// import UploadPage from './Pages/UploadPage/UploadPage';
import { CartProvider } from './Pages/ShoppingCart/CartContext';
import ShoppingCart from './Pages/ShoppingCart/ShoppingCart';
import Checkout from './component/Checkout/Checkout';
import BundleDetail from './Pages/BundleDetail/BundleDetail';
import BundleCheckout from './Pages/BundleCheckout/BundleCheckout';
import OrderPlaced from './component/Checkout/OrderPlaced';
import ManageRoutine from './Pages/ManageRoutine/ManageRoutine';
import CancelSubscription from './Pages/Cancel/Cancel';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST);

function App() {
  const location = useLocation();

  

  return (
    // <CartProvider>
      <div className={`mobile-container ${location.pathname === '/' ? 'home-container' : ''} ${location.pathname === '/Gradients' || location.pathname === '/Gradients2' ? 'Header-Radius' : ''} ${location.pathname === '/scoreexplained' ? 'home-container' : ''}`}>
        {location.pathname === '/' ? <div/> : <Header />}
        <Elements stripe={stripePromise}>
        <Routes>
          <Route path="/catalog" element={<ProductPage />} />
          <Route path="/ProductDetail/:id" element={<ProductDetail />} />
          <Route path="/ReviewDetail/:id/:type" element={<ReviewDetail />} />
          <Route path="/ReviewDetail2/:productId/:featureId" element={<ReviewDetail2 />} />
          <Route path="/Compare/:id" element={<ComparePage />} />
          <Route path="/Gradients" element={<Gradients />} />
          <Route path="/Creator/:id" element={<CreatorPage />} />
          <Route path="/Profile" element={<ProfilePage />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/" element={<MainPage />} />
          <Route path="/scoreexplained" element={<ScoreWorks />} />
          <Route path="/ShoppingCart" element={<ShoppingCart />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/BundleDetail/:id" element={<BundleDetail />} />
          {/* <Route path="/Upload" element={<UploadPage />} /> */}
          <Route path="/BundleCheckout" element={<BundleCheckout />} />
          <Route path="/OrderPlaced" element={<OrderPlaced />} />
          <Route path="/ManageRoutine" element={<ManageRoutine />} />
          <Route path="/cancel" element={<CancelSubscription />} />
        </Routes>
        <ScrollToTop />
        </Elements>

      </div>
    // </CartProvider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import CheckoutForm from './CheckoutForm';
import { BarLoader } from 'react-spinners';
import './Checkout.css';
import Footer from '../Footer/Footer';
import { Icon } from '@iconify/react';
import '../SimilarBundles/SimilarBundles.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Checkout = () => {
  const location = useLocation();
  const { clientSecret, subscriptionId, bundle, product, finalPriceRaw, annualPriceRaw, frequency, stripeId, oneTime, paymentIntentId } = location.state || {};
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const finalPrice = Number(finalPriceRaw);
  const annualPrice = Number(annualPriceRaw);

  const nextChargeDate = new Date();
  nextChargeDate.setMonth(nextChargeDate.getMonth() + 1);
  const nextChargeYear = new Date();
  nextChargeYear.setFullYear(nextChargeYear.getFullYear() + 1);

  let nextShipmentDate = new Date();
  if (frequency === 'quarterly') {
    nextShipmentDate.setMonth(nextShipmentDate.getMonth() + 3);
  } else if (frequency === 'bi-monthly') {
    nextShipmentDate.setMonth(nextShipmentDate.getMonth() + 2);
  } else if (annualPrice === finalPrice) {
    nextShipmentDate.setFullYear(nextShipmentDate.getFullYear() + 1);
  } else {
    nextShipmentDate.setMonth(nextShipmentDate.getMonth() + 1);
  }

  const formattedNextChargeDate = nextChargeDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const formattedNextChargeYear = nextChargeYear.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const formattedNextShipmentDate = nextShipmentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

  const serviceCharge = bundle
    ? 5
    : frequency === 'monthly'
      ? 12
      : frequency === 'bi-monthly'
        ? 7
        : 5.34;

  const total = finalPrice + (annualPrice !== finalPrice ? serviceCharge : 0);

  return (
    <div>
      <div className="bundle-for-you" style={{ marginTop: '12px', marginLeft: '24px', padding: '0px' }}>
        <h3>Your <span className="bundle-for-you-header-special">{bundle?.name || product?.name}</span> is ready!</h3>
      </div>
      <div className="checkout-wrapper">
        <div className="even-bigger-wrap">
        {bundle ? (
          <div className="score-card-wrap mb-2.5 score-card-bg" style={{ border: "1px solid #EEE" }}>
              
                <div className="image-grid-bundle">
                <>
                  <img src={bundle?.sunscreen?.colored_photo} alt="Product 1" style={{ borderTopLeftRadius: '8px' }} />
                  <img src={bundle?.face_wash?.colored_photo} alt="Product 2" style={{ borderTopRightRadius: '8px' }} />
                  <img src={bundle?.moisturizer?.colored_photo} alt="Product 3" style={{ borderBottomLeftRadius: '8px' }} />
                  <img src={bundle?.moisturizer?.plain_photo_1} alt="Product 4" style={{ borderBottomRightRadius: '8px' }} />
                </>
                </div>
              
            <div className="score-card-info">
              <h5 className="truncate-text">{bundle?.name || product?.name}</h5>
              {bundle && (
                <p>{bundle?.face_wash?.brand}, {bundle?.moisturizer?.brand}, {bundle?.sunscreen?.brand} <span>{/* #tag here */}</span></p>
              )}
            </div>
            {bundle && (
              <div className="score-card-score-general bg-eeee">
                <h4>{Math.round(bundle?.dotscore)} <span><Icon icon="mdi:chevron-up-circle-outline" /></span></h4>
                <i>dotScore</i>
              </div>
            )}
          </div> ) : (
          <div className="score-card-wrap mb-2.5 score-card-bg" style={{border: "1px solid #EEE"}}>
              <div className="score-card-img">
                  <img src={product.plain_photo_1} />
              </div>
              <div className="score-card-info">
                  <h5 className="truncate-text">{product.name}</h5>
                  <p>{product.brand} <span>{/* #tag here */}</span></p>
                  <h6>${product.price?.toFixed(2)}</h6>
              </div>
              <div className="score-card-score-general bg-white">
                  <h4>{product.dotscore.toFixed()} <span><Icon icon="mdi:chevron-up-circle-outline" /></span></h4>
                  <i>dotScore</i>
                  <small className={(product.dotscore.toFixed()).className}><Icon icon={(product.dotscore.toFixed()).icon} /></small>
              </div>
          </div> )}
          {bundle ? (
            <>
              {oneTime ? (<><>
              <p><b>Your routine:</b> ${bundle?.price * 2}</p>
              {annualPrice !== finalPrice && <p><b>Shipping and Service Fee:</b> $10</p>}
              {annualPrice !== finalPrice && <p><b>Total:</b> ${(bundle?.price)*2 + 10}</p>}
            </></>) 
            : 
            (<><p><b>Your routine:</b> ${finalPrice.toFixed(2)}</p>
              {annualPrice !== finalPrice && <p><b>Shipping and Service Fee:</b> ${serviceCharge.toFixed(2)}</p>}
              {annualPrice !== finalPrice && <p><b>Total:</b> ${total.toFixed(2)}</p>} </>)}
            </>
          ) : (
            <>
              {oneTime ? (<><><p><b>Your product:</b> ${product?.price.toFixed(2)}</p>
              {annualPrice !== finalPrice && <p><b>Shipping and Service Fee:</b> ${8}</p>}
              {annualPrice !== finalPrice && <p><b>Total:</b> ${product?.price + 8}</p>}</></>)
              :
              (<><><p><b>Your routine:</b> ${finalPrice.toFixed(2)- serviceCharge.toFixed(2)}</p>
              {annualPrice !== finalPrice && <p><b>Shipping and Service Fee:</b> ${serviceCharge.toFixed(2)}</p>}
              {annualPrice !== finalPrice && <p><b>Total:</b> ${finalPrice.toFixed(2)}</p>}</></>)          
            }
            </>
          )}
          {!oneTime && <>{annualPrice !== finalPrice && <p> <b>Your next charge will be:</b> {formattedNextChargeDate} </p>}
          {annualPrice === finalPrice && <p> <b>Total (service fee already included):</b> {finalPrice.toFixed(2)}</p>}
          {annualPrice === finalPrice && <p> <b>Your next charge will be:</b> {formattedNextChargeYear} </p>}
          <p> <b>Your next shipment will be:</b> {formattedNextShipmentDate} </p></>}
        </div>

        {loading ? (
          <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
            <BarLoader
              color="hsla(168, 0%, 0%, 1)"
              height={4}
            />
          </div>
        ) : (
          clientSecret && (
            <div className="main">
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm clientSecret={clientSecret} subscriptionId={subscriptionId} formattedNextChargeDate={formattedNextChargeDate}
                  formattedNextChargeYear={formattedNextChargeYear}
                  formattedNextShipmentDate={formattedNextShipmentDate} stripeId={stripeId} paymentIntentId={paymentIntentId} />
              </Elements>
            </div>
          )
        )}
      </div>
      <div style={{ marginTop: '150px' }}>
        <Footer />
      </div>
    </div>
  );
};

export default Checkout;

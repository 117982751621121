import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { getFirestore, doc, getDoc, query, where, getDocs, collection, setDoc } from "firebase/firestore";
import './ManageRoutine.css';
import Footer from '../../component/Footer/Footer';
import { Modal, Button } from "react-bootstrap";
import { AddressElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('your-publishable-key-here');

// URL-safe base64 encoding and decoding functions
const encodeProductId = (productId) => {
    return btoa(productId).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

const decodeProductId = (encodedProductId) => {
    encodedProductId = encodedProductId.replace(/-/g, '+').replace(/_/g, '/');
    // No need to add padding characters back since we'll decode directly
    return atob(encodedProductId);
};


function ManageRoutine() {
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();
    const stripe = useStripe();
    const elements = useElements();
    const [user, setUser] = useState(null);
    const [bundle, setBundle] = useState(null);
    const [bundleScore, setBundleScore] = useState(0);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [addressModalOpen, setAddressModalOpen] = useState(false);
    const [userFirebaseID, setUserFirebaseID] = useState(null);
    const [oneTimeBundles, setOneTimeBundles] = useState([]);

    const fetchUserData = async (uid) => {
        try {
            const userDoc = await getDoc(doc(db, "customers", uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setUser(userData);
                const bundleName = userData?.bundle?.bundleName;
                if (bundleName) {
                    const bundleQuery = query(collection(db, "bundles"), where("name", "==", bundleName));
                    const bundleSnapshot = await getDocs(bundleQuery);
                    if (!bundleSnapshot.empty) {
                        const bundleData = bundleSnapshot.docs[0].data();
                        setBundle(bundleData);
                        setBundleScore(bundleData.score || 0);
                    }
                }
                // Fetch products
                const productKeys = Object.keys(userData?.products || {});
                const productPromises = productKeys.map(key => getDoc(doc(db, "products", decodeProductId(key))));
                const productDocs = await Promise.all(productPromises);
                const fetchedProducts = productDocs.map(doc => ({ id: encodeProductId(doc.id), ...doc.data() }));
                setProducts(fetchedProducts);

                const oneTimeBundles = userData?.oneTime || {};
                const oneTimeList = Object.values(oneTimeBundles);
                setOneTimeBundles(oneTimeList);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
        setLoading(false);
    };

    console.log(products);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchUserData(user.uid);
                setUserFirebaseID(user.uid);
            } else {
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, [auth]);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const openAddressModal = () => {
        setAddressModalOpen(true);
    };

    const closeAddressModal = () => {
        setAddressModalOpen(false);
    };

    const handleTalkWithUs = () => {
        window.location.href = "https://calendly.com/d/hq3-2bk-znp/hunter-rohit-from-shopdot";
    };

    const handleCancelOrder = () => {
        navigate('/Cancel');
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        const date = timestamp.toDate();
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour12: true,
        });
    };

    const formatShipmentTimestamp = (shipTimestamp) => {
        if (!shipTimestamp) return '';
        const date = new Date(shipTimestamp);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const handleAddressSubmit = async (event) => {
        event.preventDefault();
        const addressElement = elements.getElement(AddressElement);
        const { value: address } = await addressElement.getValue();
        if (!address) {
            console.error("Address is undefined");
            return;
        }
        try {
            await setDoc(doc(db, 'customers', userFirebaseID), {
                ...user,
                bundle: {
                    ...user.bundle,
                    address,
                }
            }, { merge: true });
            closeAddressModal();
            fetchUserData(userFirebaseID);  // Refresh user data
        } catch (error) {
            console.error("Error updating address:", error);
        }
    };

    const bundleCanceled = user?.bundle?.canceled;

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{ margin: "16px" }}>
            <div className="profile-head" style={{ }}>
                <Link to="#" onClick={() => navigate(-1)}><Icon icon="ph:arrow-left" /></Link>
            </div>
            <div className="bundle-for-you" style={{ marginTop: '12px', marginLeft: '8px', padding: '0px' }}>
                <h3>Manage your <span className="bundle-for-you-header-special"> {bundle?.name}!</span></h3>
            </div>
            <div className="manage-body">
            {bundle ? (
                <div className="score-card-manage">
                    <div className="score-card-wrap mb-2.5 score-card-bg" style={{ border: "1px solid #EEE", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                            <div className="image-grid-bundle">
                                <img src={bundle?.sunscreen?.colored_photo} alt="Product 1" style={{ borderTopLeftRadius: '8px' }} />
                                <img src={bundle?.face_wash?.colored_photo} alt="Product 2" style={{ borderTopRightRadius: '8px' }} />
                                <img src={bundle?.moisturizer?.colored_photo} alt="Product 3" style={{ borderBottomLeftRadius: '8px' }} />
                                <img src={bundle?.moisturizer?.plain_photo_1} alt="Product 4" style={{ borderBottomRightRadius: '8px' }} />
                            </div>
                            <div className="score-card-info">
                                <h5 className="truncate-text">{bundle?.name}</h5>
                                <p>{bundle?.face_wash?.brand}, {bundle?.moisturizer?.brand}, {bundle?.sunscreen?.brand} <span></span></p>
                                <h6>${user?.bundle?.bundlePrice?.toFixed(2)}/month</h6>
                            </div>
                            <div className="score-card-score-general bg-eeee">
                                <h4>{Math.round(bundle?.dotscore)} <span><Icon icon="mdi:chevron-up-circle-outline" /></span></h4>
                                <i>dotScore</i>
                            </div>
                        </div>
                        <Link className="input-box-tell-us-think" style={{ width: '100%', marginTop: '4px' }}>
                            <div className="thumb-btn thumbs-down"><Icon icon="solar:chat-round-dots-outline" /></div>
                            <input type="text" className="feedback-input" placeholder="tell us what you think..." />
                            <div className="thumb-btn thumbs-up"><Icon icon="ph:thumbs-up" /></div>
                            <div className="thumb-btn thumbs-down"><Icon icon="ph:thumbs-down" /></div>
                        </Link>
                    </div>
                </div>
            ) : (
                <div>No bundle data available</div>
            )}


            {/* Product cards */}
            {products.map((product, index) => (
                <div key={index} className="score-card-wrap mb-2.5 score-card-bg" style={{ border: "1px solid #EEE", backgroundColor: user?.products[product.id]?.canceled ? '#FF6961' : 'white' }}>
                    <div className="score-card-img">
                        <img src={product.plain_photo_1} alt={product.name} />
                    </div>
                    <div className="score-card-info">
                        <h5 className="truncate-text">{product.name}</h5>
                        <p>{product.brand} <span>{/* #tag here */}</span></p>
                        <h6>{user?.products[product.id]?.canceled ? 'Canceled' : `$${product.price?.toFixed(2)}`}</h6>
                    </div>
                    <div className="score-card-score-general bg-white">
                        <h4>{product.dotscore.toFixed()} <span><Icon icon="mdi:chevron-up-circle-outline" /></span></h4>
                        <i>dotScore</i>
                        <small className={(product.dotscore.toFixed()).className}><Icon icon={(product.dotscore.toFixed()).icon} /></small>
                    </div>
                </div>
            ))}
            {!bundleCanceled &&<div className="manage-routine-small-text">
                <div> (If you just placed an order it might take a day or two for our system to update the next charge / shipment date)</div>
                <div> Your next charge will be <b>{formatTimestamp(user?.bundle?.nextChargeDate)}</b> with your next shipment getting ready to be sent out <b>{formatTimestamp(user?.bundle?.nextShipmentDate)}</b></div>
                <div style={{ marginTop: '16px' }}>Want to swap an item in this routine? <Link className="upgrade-to-manage"> click here to create your custom routine </Link></div>
                
            </div>}
            {bundleCanceled && <div className="issue-box" style={{backgroundColor: '#FF6961'}}>
                <div className="bundle-for-you">
                    <h3 style={{ marginTop: '-36px', marginBottom: '-12px' }}>Your bundle has been canceled</h3>
                </div>
                <div className="manage-routine-small-text" style={{ marginTop: '-48px', marginBottom: '0px', marginLeft: '12px' }}>
                    {user?.name} we can’t thank you enough for joining shopdot! We're sorry it didn't work out but we'd love to hear how we can improve!
                </div>
                <Link className="long-black-right" to="/catalog"> go back to shopping <Icon icon="ph:arrow-right" /></Link>
                <div className="or-box-wrapper">
                    or, 
                    <Link to="https://calendly.com/d/5d7-sbg-2s6"style={{ fontSize: '14px', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignContent: "center" }} >talk to us</Link>
                </div>
            </div>  }
            </div>
            <div className="previous-purchases-section">
                <h3>Previously Purchased Bundles</h3>
                {oneTimeBundles.length > 0 ? (
                    oneTimeBundles.map((oneTimeBundle, index) => (
                        <div key={index} className="score-card-wrap mb-2.5 score-card-bg" style={{ border: "1px solid #EEE" }}>
                            <div className="score-card-info">
                                <h5 className="truncate-text">{oneTimeBundle?.bundleName}</h5>
                                <p>Price: ${oneTimeBundle.bundlePrice?.toFixed(2)}</p>
                                <p>Purchased on: {oneTimeBundle.dateOfPurchase?.toDate().toLocaleDateString()}</p>

                            </div>
                        </div>
                    ))
                ) : (
                    <p>No one-time purchases found</p>
                )}
            </div>
            
            <div className="grey-wrapper-routine">
            
                <div className="bundle-for-you">
                    <h3 style={{ marginTop: '-12px', marginBottom: '-24px' }}>Upcoming Shipments</h3>
                </div>
                {!bundleCanceled &&
                <div className="shipment-widget-1">
                    <Icon className="shipment-widget__icon" icon="ph:gift"></Icon>
                    <div className="stacks-manage">
                        <div className="stacks-manage-header">
                            ships on {formatTimestamp(user?.bundle?.nextShipmentDate)}
                        </div>
                        <div className="stacks-manage-header-smaller">
                            going to <b>{user?.bundle?.address?.address?.line1}</b>
                        </div>
                    </div>
                </div> }
                <div className="manage-routine-small-text">
                    Every unit is sourced from Sephora or directly from the seller & undergoes a thorough quality inspection before being carefully packaged and shipped to you {user?.bundle?.frequency}!
                </div>

                <div className="shipment-container-organizer">

                <div className="shipment-container-wrapper">

                    <div className="bundle-for-you" style={{ marginTop: '0px', marginBottom: '0px', padding: '0' }}>
                        <h3 style={{ marginTop: '0', marginBottom: '8px' }}>Previous Shipments</h3>
                    </div>

                    <div className="manage-routine-small-text" style={{marginTop: '-6px', marginBottom: '12px', marginLeft: '0'}}>
                        Track your past shipments & payments. Let us know if you're experiencing any issues!
                    </div>

                    {user?.bundle?.pastShipments && Object.keys(user.bundle.pastShipments).length > 0 ? (
                    Object.entries(user.bundle.pastShipments).map(([time, shipment], index) => (
                        <div key={index} className="shipment-widget">
                            <Icon className="shipment-widget__icon" icon="ph:gift" />
                            <div className="stacks-manage">
                                <div className="stacks-manage-header">
                                    Shipment scheduled for {formatShipmentTimestamp(time)}
                                </div>
                                <div className="stacks-manage-header-smaller">
                                    Status: <b>{shipment.status}</b>
                                </div>
                                <div className="stacks-manage-header-smaller">
                                    Tracking Number: <b>{shipment.trackingNumber}</b>
                                </div>
                                {/* <div className="stacks-manage-header-smaller">
                                    Shipped to <b>{shipment.address?.line1}</b>
                                </div> */}
                            </div>
                        </div>
                    ))
                    ) : (
                        <div className="shipment-widget">
                            <Icon className="shipment-widget__icon" icon="ph:clock" />
                            <div className="stacks-manage">
                                <div className="stacks-manage-header">
                                    Nothing yet
                                </div>
                                <div className="stacks-manage-header-smaller">
                                    Check back later
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                    <div className="shipment-container-wrapper" style={{marginTop: '12px'}}>

                        <div className="bundle-for-you" style={{ marginTop: '0px', marginBottom: '0px', padding: '0' }}>
                            <h3 style={{ marginTop: '0', marginBottom: '8px' }}>Edit your order</h3>
                        </div>

                        <div className="manage-routine-small-text" style={{marginTop: '-6px', marginBottom: '12px', marginLeft: '0'}}>
                            Want to change the address that the routine is going to? Click here
                        </div>

                        <div>
                            <Link to="#" onClick={openAddressModal} className="long-black-right" style={{color: 'black', backgroundColor: 'white', border: '1px solid black'}}>Change your address <Icon icon="ph:arrow-right" /></Link>
                        </div>

                    </div>
                </div>
            </div>
            <div className="issue-box">
                <div className="bundle-for-you">
                    <h3 style={{ marginTop: '-36px', marginBottom: '-12px' }}>How's it going?</h3>
                </div>
                <div className="manage-routine-small-text" style={{ marginTop: '-48px', marginBottom: '0px', marginLeft: '12px' }}>
                    {user?.name} we can’t thank you enough for joining shopdot! We’d love to hear how it’s going, wether you need any extras, your feedback or anything else!
                </div>
                <Link className="long-black-right" to="https://calendly.com/d/5d7-sbg-2s6">Create your own routine <Icon icon="ph:arrow-right" /></Link>
                <div className="or-box-wrapper">
                    or, 
                    <Link to="/cancel"style={{ fontSize: '14px', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }} >cancel</Link>
                </div>
            </div>  
            <div style={{ marginTop: '150px' }}>
                <Footer />
            </div>
            <Modal
                show={modalIsOpen}
                onHide={closeModal}
                contentLabel="Cancel or Talk with Us Modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>What would you like to do?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-buttons">
                        <Button onClick={handleTalkWithUs} className="submit-btn" style={{backgroundColor:"black"}}>Talk with us!</Button>
                        <Button onClick={handleCancelOrder} className="submit-btn" style={{ backgroundColor: "white", color: 'black', border: '1px solid black' }}>Cancel my order</Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{backgroundColor:"black", border: '1px solid black'}} onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={addressModalOpen}
                onHide={closeAddressModal}
                contentLabel="Change Address Modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change Your Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleAddressSubmit}>
                        <AddressElement options={{ mode: 'shipping' }} />
                        <div className="modal-buttons" style={{ marginTop: '20px' }}>
                            <Button type="submit" className="submit-btn" style={{ backgroundColor: "black" }}>Save Address</Button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ backgroundColor: "black", border: '1px solid black' }} onClick={closeAddressModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

// Export the ManageRoutine component as default
export default ManageRoutine;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from "../../component/Footer/Footer";
import HeroSectionNew from "../../component/HeroSection/HeroSectionNew";
import SponsoredCardSection from "../../component/SponsoredCardSection/SponsoredCardSection";
import './MainPage.css';
import AiAndTechSection from '../../component/AiAndTechSection/AiAndTechSection';
import OurMissionSection from '../../component/OurMissionSection/OurMissionSection';
import HeroSectionDesktop from '../../component/HeroSectionDesktop/HeroSectionDesktop';
import AiAndTechSectionDesktop from '../../component/AiAndTechSection/AiAndTechSectionDesktop';
import OurMissionSectionDesktop from '../../component/OurMissionSection/OurMissionSectionDesktop';
import { Link } from 'react-router-dom';

function MainPage() {
  const location = useLocation();
  const products = [
    "Aqua_bomb_true_cream",
    "Beauty_of_Joseon_Relief_Sun__Rice+Probiotics(50ml)"
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Function to add or remove the class that controls the background
    const updateBackground = () => {
      if (location.pathname === '/') {
        if (window.innerWidth >= 900) {
          document.body.classList.add('no-background-image');
        } else {
          document.body.classList.remove('no-background-image');
          document.body.style.backgroundColor = 'white'; // Set background color to white for narrower screens
        }
      } else {
        document.body.classList.remove('no-background-image'); // Remove the class on other pages
        document.body.style.backgroundColor = ''; // Reset any inline background color
      }
    };

    updateBackground(); // Call function on load

    // Listen to window resize events
    window.addEventListener('resize', updateBackground);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', updateBackground);
      document.body.classList.remove('no-background-image'); // Remove on cleanup
      document.body.style.backgroundColor = ''; // Reset any background color
    };
  }, [location.pathname]);

  return (
    <>
      {isMobile ? (
        <div className="sections-wrapper" style={{ marginBottom: '100px', backgroundColor: 'var(--Black, #1F1F1F)' }}>
          <HeroSectionNew />
          <SponsoredCardSection productIds={products} />
          <div className="brand-image-container" style={{ marginBottom: '48px' }}>
            
            <img src="/images/brands_frame_mainpage.png" alt="Brand" className="brand-image" />
            
          </div>
          <AiAndTechSection style={{ marginBottom: '0' }} />
          <div style={{ 
              marginTop: '-76px', 
              marginBottom: '-36px', width: '80%', maxWidth: '800px', overflow: 'hidden', display: 'flex', justifyContent: 'center', marginLeft: 'auto',  marginRight: 'auto'  /* Center the container */ }}>
            <Link to="https://www.instagram.com/youcanshopdot/">
              <img
                style={{  borderRadius: '8px', width: '100%',  height: 'auto', objectFit: 'cover',
                }} src="/images/man_board_mainpage.png" alt="Man with sign"
                className="brand-image"
              />
            </Link>
          </div>
          <OurMissionSection style={{ marginBottom: '0' }} />
          <Footer style={{ marginBottom: '36px' }} />
        </div>
      ) : (
        <div className="sections-wrapper-desktop" style={{ backgroundColor: 'var(--Black, #1F1F1F)' }}>
          <HeroSectionDesktop products={products} />
          <AiAndTechSectionDesktop />
          <div style={{ marginRight: '-48.21%', marginTop: '0px', display: 'flex', justifyContent: 'center', marginBottom: '-50px' }}>
            <img style={{ width: '35%' }} src="/images/man_board_mainpage.png" alt="Man with sign" className="brand-image" />
          </div>
          <OurMissionSectionDesktop />
          <Footer style={{ marginBottom: '36px' }} />
        </div>
      )}
    </>
  );
}

export default MainPage;

import React from "react";
import './OurMissionSection.css';
import { Icon } from "@iconify/react";

function OurMissionSectionDesktop() {
    return (
        <div className="our-mission-desktop" style={{backgroundColor: 'white', paddingTop: '150px'}}>

            <div className="out-mission-desktop-container" style={{backgroundColor: "#EEE", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}> 

                    <div style={{display: 'flex', justifyItems: 'center', alignItems: 'left', flexDirection: 'column', width: '700px', paddingTop: '72px'}}>
                    
                    <div className="ai-tech-badge" style={{fontSize: '14px'}}>Our Mission</div>
                    <div className="our-mission-text-desktop" style={{fontSize: '40px'}}><b>We spent 2 years studying all of skincare. Now, we’re helping people </b> find, fix & stay on top of their routines.</div>
                    <div className="our-mission-paragraph-desktop"> You can get started now with our quiz. It’s how we understand your skin to offer smart suggestions for a better routine. We make it really easy to swap products, find whats right for you & stay stocked up (with a subscription). </div>
                    
                    </div>

                    <div className="lil-wrap" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '54px', backgroundColor: 'var(--Black, #1F1F1F)', padding: '48px', paddingBottom: '24px', width: '1150px', borderRadius: '12px', marginBottom: '24px'}}>
                        <div className='our-mission-title-build-black'> build your routine</div>

                        <div className="lorenas-butt"  style={{maxWidth: '570px', marginTop: '54px'}}>
                        <OurMissionBox
                            number="01"
                            title="Find Your Routine"
                            content="We’ll help find what’s right for you, then help you stay consistent. It takes just 1 minute. Get started now."
                            buttonText="Start Here"
                            background="linear-gradient(135deg, #4D82F3, #39AAE6)"
                            buttonLink="https://form.typeform.com/to/fhw1kz60"
                           
                        />

                        <div style={{
                        justifyContent: 'center', 
                        display: 'flex', 
                        margin: '48px', 
                        position: 'relative'
                        }}>
                        {/* The border positioned absolutely, behind the widget */}
                        <div style={{
                            position: 'absolute', 
                            top: '15px', /* Adjust the height to move the border lower */
                            width: '120%', /* Make the border a little wider */
                            left: '-10%', /* Center it horizontally */
                            borderTop: '1px solid #EEE', 
                            zIndex: 0 /* Ensure the border is behind the widget */
                        }}></div>

                        {/* The widget itself */}
                        <div className="ai-tech-badge" style={{
                            fontSize: '14px', 
                            backgroundColor: 'rgba(117, 117, 117, 0.50)', 
                            borderRadius: '8px',
                            position: 'relative', /* Ensure the widget is on top of the border */
                            zIndex: 1 /* Make sure it's above the border */
                        }}>
                            Or just explore
                        </div>
                        </div>

                        <div className="or-just-explore-container">
                        <OurMissionBox
                            number="02"
                            title="The wrong sunscreen have you looking like a ghost?"
                            content="The wrong sunscreen can break you out, sit greasily under your makeup, or leave a white cast on medium & dark skin tones."
                            buttonText="Explore Sunscreens"
                            background="var(--Black, #1F1F1F)"
                            buttonLink="/catalog"
                            isBottomButton={true}
                        />

                        <OurMissionBox
                                number="03"
                                title="Everyone needs a great face wash"
                                content="Dermatologists advise everyone to use daily face wash, regardless of makeup usage, to effectively remove build up."
                                buttonText="Explore Cleansers"
                                background="var(--Black, #1F1F1F)"
                                buttonLink="/catalog"
                                isBottomButton={true}
                            />

                        <OurMissionBox
                                number="04"
                                title="The wrong sunscreen have you looking like a ghost?"
                                content="Moisturizers affect dry, oily & combination skin differently because each have their own unique hydration needs & oil production.

                                Let's find the right moisturizer for you."
                                buttonText="Explore Moisturizers"
                                background="var(--Black, #1F1F1F)"
                                buttonLink="/catalog"
                                isBottomButton={true}
                            />


                        </div>


                        </div>

                    </div>


            </div>
            
        </div>
    )
}

export default OurMissionSectionDesktop;


function OurMissionBox({ number, title, content, buttonText, background, buttonLink, isBottomButton }) {
    return (
      <div className="our-mission-box" style={{ background, minWidth: '350px' }}>
        <div>
          <div className="our-mission-box-number">{number}</div>
        </div>
  
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <div className="our-mission-box-title">{title}</div>
          <div className="our-mission-box-content">{content}</div>
  
          {/* Conditionally apply the 'button-at-bottom' class if isBottomButton is true */}
          <a 
            href={buttonLink} 
            className={`our-mission-box-button ${isBottomButton ? 'button-at-bottom' : ''}`}
          >
            {buttonText} <Icon icon="formkit:arrowright"/>
          </a>
        </div>
      </div>
    );
  }
  
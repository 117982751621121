import React from "react";
import "./OurMissionSection.css";
import { Icon } from "@iconify/react";

function OurMissionSection() {
  return (
    <div className="ai-tech-section" style={{ background: "#EEE", display: 'flex', flexDirection: 'column', alignContent: 'center', padding: '76px 20px 36px 24px' }}>
        
        <div style={{maxWidth: '340px', marginBottom: '12px'}}>

            <div className="our-mission-title">our mission</div>
            <div className="our-mission-content">
                <b>Now, 2 years later, we’re helping people</b> find, fix & stay on top of their routines.
            </div>
            <div className="our-mission-paragraph">
                You can get started now with our <a to="https://form.typeform.com/to/fhw1kz60"><b><u>quiz</u></b></a>. It’s how we understand your skin to offer smart suggestions for a
                better routine. We make it really easy to swap products, find what's right for you & stay stocked up (with a
                subscription).
            </div>

        </div>

      <div style={{ textAlign: "right" }}>
        <div className="ai-tech-badge" style={{ display: "inline-block", maxWidth: '250px', marginBottom: '8px', fontSize: '14.5px' }}>build a routine</div>
      </div>

      {/* Add the two OurMissionBox components below */}
      <OurMissionBox
        number="01"
        title="Find Your Routine"
        content="We’ll help find what’s right for you, then help you stay consistent. It takes just 1 minute. Get started now."
        buttonText="Start Here"
        background="linear-gradient(135deg, #4D82F3, #39AAE6)"
        buttonLink="https://form.typeform.com/to/fhw1kz60"
      />

        <div style={{ textAlign: "right" }}>
            <div className="ai-tech-badge" style={{ display: "inline-block", maxWidth: '250px', marginBottom: '8px', fontSize: '14.5px'}}>or just explore</div>
        </div>
      <OurMissionBox
        number="02"
        title="The wrong sunscreen have you looking like a ghost?"
        content="The wrong sunscreen can break you out, sit greasily under your makeup, or leave a white cast on medium & dark skin tones."
        buttonText="Explore Sunscreens"
        background="var(--Black, #1F1F1F)"
        buttonLink="/catalog"
      />

    <OurMissionBox
            number="03"
            title="Everyone needs a great face wash"
            content="Dermatologists advise everyone to use daily face wash, regardless of makeup usage, to effectively remove build up."
            buttonText="Explore Cleansers"
            background="var(--Black, #1F1F1F)"
            buttonLink="/catalog"
        />

    <OurMissionBox
            number="04"
            title="The wrong sunscreen have you looking like a ghost?"
            content="Moisturizers affect dry, oily & combination skin differently because each have their own unique hydration needs & oil production.

            Let's find the right moisturizer for you."
            buttonText="Explore Moisturizers"
            background="var(--Black, #1F1F1F)"
            buttonLink="/catalog"
        />
    </div>
  );
}

// Updated OurMissionBox to accept the additional props like number, buttonLink, etc.
function OurMissionBox({ number, title, content, buttonText, background, buttonLink }) {
  return (
    <div className="our-mission-box" style={{ background }}>
        <div>

            <div className="our-mission-box-number">{number}</div>

        </div>

        <div>

            <div className="our-mission-box-title">{title}</div>
            <div className="our-mission-box-content">{content}</div>
            <a href={buttonLink} className="our-mission-box-button" style={{marginRight: 'auto'}}>{buttonText} <Icon icon="formkit:arrowright"/></a>

        </div>
    </div>
  );
}

export default OurMissionSection;

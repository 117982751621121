import { Icon } from "@iconify/react";
import { dot } from "../../images";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer-section">
      <div className="footer d-flex mx-auto">
        <div className="footer-content">
        <div className="false-header" style={{color: 'black', marginTop: '0', fontWeight: '800'}}>
        <div>•shopdot</div>
        </div>
          <div className="social-medias">
            <a href="https://www.instagram.com/youcanshopdot/" className="social-media">
              <Icon icon="mdi:instagram" color="white" width="15" />
            </a>
            <a href="https://www.tiktok.com/@youcanshopdot" className="social-media">
            <Icon icon="tabler:brand-tiktok" color="white" width="15" />
            </a>
            <a href="mailto:hunter@theshopdot.com" className="social-media">
              <Icon icon="ic:outline-email" color="white" width="15" />
            </a>
          </div>
          <p className="footer-text mb-0">
            Copyright 2024, adko. LLC. • <span> <a to="https://form.typeform.com/to/fhw1kz60">Find your routine</a></span>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState } from 'react';
import './AiAndTechSection.css'; // Link to the CSS file for custom styles
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const AiAndTechSection = () => {
  const [showMore, setShowMore] = useState(false); // State to track if "See More" content is visible

  const toggleShowMore = () => {
    setShowMore(prevState => !prevState); // Toggle the state
  };

  return (
    <div className="ai-tech-section">
      <div className="ai-tech-header">
        <div className="ai-tech-badge">ai & tech.</div>
        <div className="ai-tech-title">
          <div className="hard-container">
            <p>
              we know 
              <br/>finding skincare 
              <br/>is{' '}
              <span className="highlighted-text hard-text">
                <Icon icon="mdi:warning-circle-outline" /> hard
              </span>
            </p>
          </div>
          <img
            style={{
              position: 'relative',
              width: '25%',
              top: '-15px',
              right: '-60px',
            }}
            src="images/arrow_mainpage.png"
            alt="arrow"
            className=""
          />
          <div className="making-it-easy">
            <p>
              we’re making 
              <br/> it{' '}
              <span className="highlighted-text easy-text">
                <Icon icon="material-symbols:check-circle-outline" /> easy
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="ai-tech-details">
        <div className="stat-section" style={{ maxWidth: '300px' }}>
          <p className="stat-title">here's what it takes:</p>
          <h2>34+</h2>
          <p className="stat-title">Skincare Experts</p>
          <p>  
            We’ve worked with dermatologists & biochemists to understand exactly how to analyze, pair and recommended products for safety and results.
          </p>
        </div>

        <div
          className="stat-section right-aligned"
          style={{
            marginTop: '15px',
            maxWidth: '300px',
            marginLeft: 'auto',
          }}
        >
          <h2>5.67M</h2>
          <p className="stat-title">Verified Reviews</p>
          <p>
            We gather and organize millions of reviews from major retailers,
            verify authenticity, then analyze details and tone to uncover how a
            product can affect your skin.
          </p>
        </div>
      </div>

      <div className="ai-tech-tags right-aligned">
        <h2 className="stat-section">
          <b>attributes include:</b>
        </h2>
        <div className="tags" style={{ maxWidth: '300px', marginLeft: 'auto' }}>
          <div className="tag">
            <Icon icon="ph:circle-dashed" /> dry skin
          </div>
          <div className="tag">
            <Icon icon="ph:circle-half-tilt" /> oily skin
          </div>
          <div className="tag">
            <Icon icon="material-symbols:circle-outline" /> combo. skin
          </div>
          <div className="tag">
            <Icon icon="ph:drop-half" /> acne
          </div>
          <div className="tag">
            <Icon icon="material-symbols:check-circle-outline" /> highly recommends
          </div>
        </div>
      </div>

      {!showMore &&
      <div className="see-more">
        <button
          className="see-more-button"
          onClick={toggleShowMore}
          style={{ backgroundColor: 'black', color: 'white' }}
        >
          {showMore ? 'See Less' : 'See More'}{' '}
          <Icon icon={showMore ? 'formkit:arrowup' : 'formkit:arrowdown'} />
        </button>
      </div>
      }

      {showMore && (
        <div className="additional-content">
          <div className="ai-tech-details">
            <div className="stat-section" style={{ maxWidth: '300px' }}>
              <h2>91</h2>
              <p className="stat-title">Attributes Per Product</p>
              <p>
              We cross-check reviewer insights and expert opinions with precise knowledge of product ingredients and interactions, allowing us to classify each product according to over 91 attributes. That’s what backs every smart suggestion we make is well-supported.
              </p>
            </div>

            <h2 className="stat-section">
              <b>more attributes:</b>
            </h2>

            <div
              className="tags"
              style={{
                marginTop: '4px',
                maxWidth: '300px',
                marginRight: 'auto',
                justifyContent: 'left',
              }}
            >
              <div className="tag">
                <Icon icon="ph:sunglasses" /> no white cast
              </div>
              <div className="tag">
                <Icon icon="ph:highlighter-circle" /> great under makeup
              </div>
              <div className="tag">
                <Icon icon="ph:rainbow" /> great for acne
              </div>
              <div className="tag">
                <Icon icon="ph:hand-soap" /> never irritates skin
              </div>
              <div className="tag">
                <Icon icon="ph:carrot" /> mineral-based
              </div>
              <div className="tag">
                <Icon icon="octicon:beaker-16" /> chemical-based
              </div>
            </div>

            <div
              className="stat-section right-aligned"
              style={{
                marginTop: '15px',
                maxWidth: '300px',
                marginLeft: 'auto',
              }}
            >
              <h2>95.3%</h2>
              <p className="stat-title">Suggestion Accuracy</p>
              <p>
              Our model infers your skin’s sensitivities then offers smart suggestions to improve your current routine. 95% of our customers report a positive experience with the first product we suggest to them. 
              </p>
            </div>

            <div className="stat-section" style={{ maxWidth: '300px', marginTop: '12px' }}>
              <h2>all-in-1</h2>
              <p className="stat-title">Easy experience</p>
              <p>
              We’re building a smart, accessible and easy-to-use site loved by honest skincare enthusiasts & absolute new-bees.
              <br></br>
              <br></br>
              It's still really early we'd love to get your <b><u>feedback.</u></b>
              </p>
            </div>

            <Link className="find-your-routine">
              Find your routine <Icon icon="formkit:arrowright" />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default AiAndTechSection;

import React from "react";
import "./HeroSectionNew.css";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

function HeroSectionNew() {
  const videoUrl = "https://firebasestorage.googleapis.com/v0/b/shopdot-b9a58.appspot.com/o/etc%2FStory%20(50s)-2.mov?alt=media&token=0f59351a-32eb-4d8f-bb27-29f9d7940d9c"
  // const videoUrl = "https://firebasestorage.googleapis.com/v0/b/shopdot-b9a58.appspot.com/o/etc%2Fexport_1725894588035.MOV?alt=media&token=a56bc859-10e9-40eb-85f9-42a39ab2ef0b";

  return (
    <div className="hero-container">
      {/* Video background */}
      <video
        src={videoUrl}
        autoPlay
        muted
        loop
        playsInline
        className="hero-video"
      />
      
      <div className="false-header">
        <div>•shopdot</div>
        <Link className="white-circle" to="/catalog"><Icon icon="formkit:arrowright" style={{color: 'black'}}></Icon></Link>
      </div>

      <div className="hero-content" style={{display: 'flex', flexDirection: 'column', alignSelf: 'left'}}>
        <div className="big-text">skincare <div>shopping </div>
        
        made easy</div>

        <div className="small-holder" style={{maxWidth: '206px'}}>
          <div className="small-text">
            We spent 2 years studying all of skincare. Now, we’re helping 200+ people find, fix & stay on top of their routines.
          </div>
        </div>


      </div>
    </div>
  );
}

export default HeroSectionNew;

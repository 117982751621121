import React, { useState } from 'react';
import './AiAndTechSection.css'; // Link to the CSS file for custom styles
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const AiAndTechSection = () => {
  const [showMore, setShowMore] = useState(false); // State to track if "See More" content is visible

  const toggleShowMore = () => {
    setShowMore(prevState => !prevState); // Toggle the state
  };

  return (
    <div className="ai-tech-section" style={{}}>
      <div className="ai-tech-header">
        <div className="ai-tech-badge" style={{marginRight: '65%'}}>ai & tech.</div>
        <div className="ai-tech-title">
          <div className="hard-container">
            <p>we know finding</p>
            <div style={{ justifySelf: 'center', display: 'flex', gap: '5px' }}>
              <span>skincare is </span>
              <span className="highlighted-text hard-text">
                <Icon icon="mdi:warning-circle-outline" /> hard
              </span>
            </div>
          </div>
          <img style={{ position: 'relative', width: '28%', marginLeft: '30%', marginTop: '-3%' }} src="images/arrow_mainpage_big.png" alt="arrow" className="brand-image" />
          <div className="making-it-easy" style={{marginRight: '-5%', marginTop: '-7%'}}>
            <p>we’re making</p>
            <div style={{ display: 'flex', justifyContent: 'right', gap: '5px' }}>
              <span>it </span>
              <span className="highlighted-text easy-text">
                <Icon icon="material-symbols:check-circle-outline" /> easy
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="ai-tech-details-desktop" style={{marginLeft: '24px', marginTop: '36px', marginBottom: '-164px', zIndex: '2', position: 'relative'}}>
        <p className="stat-title" style={{marginBottom: '48px', fontSize: '36px'}}>here's what it takes</p>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div className="stat-section">
            <h2>34+</h2>
            <p className="stat-title">Skincare Experts</p>
            <p>We worked with dermatologists to understand exactly how products could be analyzed, paired & recommended for accuracy, safety and results.</p>
          </div>
          <div className="stat-section">
            <h2>5.67m</h2>
            <p className="stat-title">Verified Reviews</p>
            <p>We gather and organize millions of reviews from major retailers, verify authenticity, then analyze details and tone to uncover how a product can affect your skin.</p>
          </div>
        </div>
        <div className='bottom-half-desktop-ai-tech' style={{marginLeft: '-24px', marginRight: '-48px', marginBottom: '-48px', padding: '24px', borderRadius: '0 0 12px 12px', borderTop: ''}}>
          <div className="filter-tags-desktop" style={{marginBottom: '12px'}}>filter by these tags:</div>
          <div className="tags">
            <div className="tag"><Icon icon="ph:circle-dashed" />dry skin</div>
            <div className="tag"><Icon icon="ph:circle-half-tilt" />oily skin</div>
            <div className="tag"><Icon icon="material-symbols:circle-outline" />combo. skin</div>
            <div className="tag"><Icon icon="ph:drop-half" />acne</div>
            <div className="tag"><Icon icon="material-symbols:check-circle-outline" />highly recommends</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiAndTechSection;

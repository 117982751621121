import React, { useEffect, useState, useRef } from "react";
import { doc, getDoc, collection, getDocs, query, limit, orderBy, where } from "firebase/firestore";
import { db } from "../../utils/firebase-config";
import "./SponsoredCardSection.css";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import DotScore from "../DotScore/DotScore";

function SponsoredCardSection({ productIds }) {
  const [products, setProducts] = useState([]);
  const [selectedTab, setSelectedTab] = useState("build"); // State to track the selected section
  const [selectorStyle, setSelectorStyle] = useState({ left: 0, width: 0 }); // For the selector animation
  const tabsRef = useRef([]); // To store references to the buttons
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility
  const [skincareGoals, setSkincareGoals] = useState({
    reduceAcne: false,
    drySkin: false,
    redness: false,
    healthySkin: false,
    other: false,
  });
  const [selectedSkincareGoal, setSelectedSkincareGoal] = useState('');
  const [searchQuery, setSearchQuery] = useState(""); // For the search input
  const [searchResults, setSearchResults] = useState([]); // For storing search results

  const fetchProducts = async (searchTerm) => {
    try {
      const productsRef = collection(db, "products");
      let q;
      if (searchTerm) {
        // Fetch products by search term
        q = query(
          productsRef,
          where("search_terms", ">=", searchTerm.toLowerCase()),
          where("search_terms", "<=", searchTerm.toLowerCase() + "\uf8ff"),
          where('hidden', '==', false),
          orderBy("search_terms"),
          orderBy("dotscore", "desc"),
          limit(5)
        );
      } else {
        // Default: fetch top 5 products by dotscore
        q = query(
          productsRef,
          orderBy("dotscore", "desc"),
          where('hidden', '==', false),
          limit(5)
        );
      }

      const snapshot = await getDocs(q);
      const fetchedProducts = snapshot.docs.map((doc) => ({ ...doc.data(), productId: doc.id }));
      setSearchResults(fetchedProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSearch = () => {
    if (searchQuery.trim()) {
      fetchProducts(searchQuery.trim());
    }
  };

  useEffect(() => {
    // Fetch default products when the search modal is opened
    if (isModalOpen) {
      fetchProducts();
    }
  }, [isModalOpen]);

  const renderSearchResults = () => {
    return searchResults.map((product) => (
      <Link to={`/ProductDetail/${product.productId}`} key={product.productId} style={{ marginTop: "12px" }}>
        <div className="included-product" style={{ marginTop: "12px" }}>
          <div className="included-product-top" style={{ width: "100%", border: 'none', paddingBottom: '0' }}>
            <img className="score-card-img" src={product.colored_photo} alt={product.name} />
            <div className="score-card-info">
              <h1>{product.name}</h1>
              <p>{product.brand}</p>
              <h1>${product?.price?.toFixed(2)}</h1>
            </div>
            <DotScore dotscore={product?.dotscore} grey={true} />
          </div>
        </div>
      </Link>
    ));
  };

  // Fetch product data from Firestore using product IDs
  useEffect(() => {
    const fetchProductData = async () => {
      const fetchedProducts = [];

      for (let productId of productIds) {
        try {
          const productDoc = await getDoc(doc(db, "products", productId));
          if (productDoc.exists()) {
            fetchedProducts.push({ ...productDoc.data(), productId });
          }
        } catch (error) {
          console.error("Error fetching product:", error);
        }
      }

      setProducts(fetchedProducts);
    };

    fetchProductData();
  }, [productIds]);

  // Update selector position and width on tab change
  useEffect(() => {
    const activeTab = tabsRef.current[selectedTab];
    if (activeTab) {
      setSelectorStyle({
        left: activeTab.offsetLeft,
        width: activeTab.offsetWidth,
      });
    }
  }, [selectedTab]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTab("build"); // Switch back to the build tab when modal is closed
  };

  const handleOptionClick = (option) => {
    setSelectedSkincareGoal(option);
    window.open("https://form.typeform.com/to/fhw1kz60", "_blank");
  };

  const buildQuestions = () => (
    <div className="skincare-goals-section">
      <div className="section-header-mainpage">What's your skincare goal?</div>
      <div className="options-container">
        <div className={`option-box ${selectedSkincareGoal === 'reduceAcne' ? 'selected' : ''}`} onClick={() => handleOptionClick('reduceAcne')}>
          <div className="light-grey-box"></div>
          <div className="yearly-choice-header">
            <b>Reduce acne & breakouts</b>
          </div>
        </div>
        <div className={`option-box ${selectedSkincareGoal === 'drySkin' ? 'selected' : ''}`} onClick={() => handleOptionClick('drySkin')}>
          <div className="light-grey-box"></div>
          <div className="yearly-choice-header">
            <b>Combat dry skin</b>
          </div>
        </div>
        <div className={`option-box ${selectedSkincareGoal === 'redness' ? 'selected' : ''}`} onClick={() => handleOptionClick('redness')}>
          <div className="light-grey-box"></div>
          <div className="yearly-choice-header">
            <b>Combat redness & irritation</b>
          </div>
        </div>
        <div className={`option-box ${selectedSkincareGoal === 'healthySkin' ? 'selected' : ''}`} onClick={() => handleOptionClick('healthySkin')}>
          <div className="light-grey-box"></div>
          <div className="yearly-choice-header">
            <b>I just want healthy skin!</b>
          </div>
        </div>
        <div className={`option-box ${selectedSkincareGoal === 'other' ? 'selected' : ''}`} onClick={() => handleOptionClick('other')}>
          <div className="light-grey-box"></div>
          <div className="yearly-choice-header">
            <b>Other</b>
          </div>
        </div>
      </div>
    </div>
  );

  const exploreProducts = () => {
    return products.map((product) => (
      <Link to={`/ProductDetail/${product.productId}`} key={product.productId} style={{ marginTop: '12px' }}>
        <div className="included-product">
          <div className="included-product-top" style={{ width: '100%' }}>
            <img className="score-card-img" src={product.colored_photo} alt={product.name} />
            <div className="score-card-info">
              <h1>{product.name}</h1>
              <p>{product.brand}</p>
              <h1>${product?.price.toFixed(2)}</h1>
            </div>
            <DotScore dotscore={product?.dotscore} grey={true} />
          </div>
          <p className="small-text-main-page">
            Each shipment contains <b>{product.ounces} oz.</b> bottles supplied by {product.brand}.
          </p>
        </div>
      </Link>
    ));
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "build":
        return (
          <>
            <div className="big-text-main-page">the routine that's right for you</div>
            {buildQuestions()}
            <Link to="https://form.typeform.com/to/fhw1kz60" className="filter-button-sort" style={{ margin: '12px' }}>
              Continue <Icon icon="formkit:arrowright"></Icon>
            </Link>
            <div className="small-text-main-page" style={{ borderTop: '1px solid lightgray', paddingTop: '12px', maxWidth: '335px' }}>
              <b>It’s what we do. </b> We’ll ask about your skin, your goals, bad past experiences & even your current routine. Then we’ll curate a routine just for you! <i>Takes 1 minute.</i>
            </div>
          </>
        );
      case "explore":
        return (
          <>
            <div className="big-text-main-page">top-rated sunscreens, skincare & more</div>
            {exploreProducts()}
            <Link to="/catalog" className="filter-button-sort" style={{ margin: '12px' }}>
              See All <Icon icon="ph:magnifying-glass" style={{ fontSize: '16px' }}></Icon>
            </Link>
            <div className="small-text-main-page" style={{ borderTop: '1px solid lightgray', paddingTop: '12px', maxWidth: '335px' }}>
              <p><b>Top-rated? </b>We’ve analyzed millions of reviews, ingredient profiles, and insights from dermatologists and skincare enthusiasts to find what’s right for you.</p>
              <p>We call it the dotScore. Think Rotten Tomatoes but skincare—to tell how well a product hydrates, its texture, scent, and if it’ll match your skin type, tone, preferences & concerns.</p>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="sponsored-card-section" style={{ margin: '5px', minHeight: '700px', maxWidth: '400px', justifyContent: 'start' }}>
        <div className="ai-pill">
          <Icon icon="heroicons-solid:lightning-bolt" style={{ fontSize: '16px' }}></Icon>ai-assisted
        </div>
        <nav className="tabs-navigation">
          <div className="tab-selector" style={selectorStyle}></div>
          <button ref={(el) => (tabsRef.current["build"] = el)} onClick={() => setSelectedTab("build")} className={`${selectedTab === "build" ? "active-tab build" : ""}`}>
            build
          </button>
          <button ref={(el) => (tabsRef.current["search"] = el)} onClick={openModal} className={selectedTab === "search" ? "active-tab" : ""}>
            search
          </button>
          <button ref={(el) => (tabsRef.current["explore"] = el)} onClick={() => setSelectedTab("explore")} className={selectedTab === "explore" ? "active-tab" : ""}>
            explore
          </button>
        </nav>

        <div className="tab-content">{renderContent()}</div>
      </div>

      {/* Modal for Search */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close" onClick={closeModal}>
              <Icon icon="ph:x-bold" style={{ fontSize: '24px' }}/>
            </button>
            <div className="big-text-main-page">Search products in our system</div>
            <div className="skincare-goals-section" style={{ minHeight: '500px', justifyContent: 'start' }}>
              <div className="search-bar" style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Enter search term..."
                  style={{ border: '1px solid #EEE', color: 'black', padding: '8px', borderRadius: '12px' }}
                />
                <button className="desktop-signin-mainpage" style={{ backgroundColor: 'black', border: '1px solid white' }} onClick={handleSearch}>
                  Search <Icon icon="ph:magnifying-glass"></Icon>
                </button>
              </div>
              <div className="search-results">{renderSearchResults()}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SponsoredCardSection;

import React from 'react';
import './HeroSectionDesktop.css';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import SponsoredCardSection from '../SponsoredCardSection/SponsoredCardSection';

function HeroSectionDesktop({ products }) {

    // const videoUrl = "https://firebasestorage.googleapis.com/v0/b/shopdot-b9a58.appspot.com/o/etc%2Fexport_1725894588035.MOV?alt=media&token=a56bc859-10e9-40eb-85f9-42a39ab2ef0b";
    const videoUrl = "https://firebasestorage.googleapis.com/v0/b/shopdot-b9a58.appspot.com/o/etc%2FStory%20(50s)-2.mov?alt=media&token=0f59351a-32eb-4d8f-bb27-29f9d7940d9c"

    return (
        <>
            <div className="desktop-mainpage-background">

                <div className="false-header">

                    <Link className="search-bar-mainpage" to="/catalog" style={{display: 'inline-flex', justifyContent: 'center'}}>

                        <div style={{display: 'flex', justifyContent: "center", alignItems: 'center', gap: '5px'}}><Icon icon="ph:magnifying-glass"></Icon>search top-rated skincare products</div>
                        <div className="bolt-holder"><Icon icon="heroicons-solid:lightning-bolt" style={{ color: 'var(--Blue-Gradient, #4D82F3)' }}></Icon></div>

                    </Link>

                    <div style={{display: 'flex', flexDirection: 'row', gap: '5px', position: 'absolute', right: '48px'}}>

                        <Link className="desktop-signin-mainpage">Sign-in</Link>
                        <Link className="desktop-signin-mainpage" style={{border: '1px solid var(--White, #FFF)'}}>Sign-up</Link>
                    </div>

                </div>

                

                <div className="desktop-wrapper-hero">

                    <div className="hero-container-desktop">

                        <div className="desktop-company-name">
                            shopdot
                        </div>

                        <div className="desktop-massive-title">
                            skincare shopping made easy
                        </div>

                        <div className="desktop-smaller-text">
                            We spent 2 years studying all of skincare. Now, we’re helping 200+ people find, fix & stay on top of their routines.
                        </div>

                    </div>

                    <div className="desktop-sponsor-section" style={{marginTop: '72px'}}>

                        <SponsoredCardSection productIds={products} />

                        <div className="desk-video-top">
                            <video
                                src={videoUrl}
                                autoPlay
                                muted
                                loop
                                playsInline
                                className="hero-video"
                                style={{transform: "translateY(-20%)", borderRadius: '0px'}}
                            />
                        </div>

                        <div className="desk-video-bottom">
                            <video
                                src={videoUrl}
                                autoPlay
                                muted
                                loop
                                playsInline
                                className="hero-video"
                                style={{transform: "translateY(-40%)", borderRadius: '0px'}}
                            />
                        </div>

                    </div>

                    

                </div>

                {/* <div>

                    <img src="/images/desk-mainpage-brands.png" alt="brands" className="brand-image" />

                </div> */}




            </div>
        </>
    )
}

export default HeroSectionDesktop;